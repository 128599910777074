import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

const db = getFirestore()
const collectionPrefix = envManager.getPrefix()
const collectionId = "messages"
const collectionName = collectionPrefix + collectionId

class Message{
    constructor(id, sender, text, date, convertsation){
        this.id = id
        this.sender = sender
        this.text = text
        this.date = date
        this.convertsation = convertsation
    }

    // get
    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        const messages = []

        response.forEach(document => {
            const data = document.data()
            const tmp_message = new Message(document.id, data.sender, data.text, data.date, data.convertsation)
            messages.push(tmp_message)
        })

        return messages
    }
    static async getByConv(convId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("convertsation", "==", convId))
        const response = await getDocs(documentsQuery)

        const messages = []

        response.forEach(document => {
            const data = document.data()
            const message = new Message(document.id, data.sender, data.text, data.date, data.convertsation)
            messages.push(message)
        })
        return messages
    } 

    // listen
    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)
        const unsub = onSnapshot(collectionRef, snapshot => {
            const messages = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_message = new Message(document.id, data.sender, data.text, data.date, data.convertsation)
                messages.push(tmp_message)
            })

            if(callback != null){
                callback(messages)
            }
        })
        return unsub
    }
    static listenByConv(convId, callback = null){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("convertsation", "==", convId))
        
        const unsub = onSnapshot(documentsQuery, snapshot => {
            const messages = []

            snapshot.forEach(document => {
                const data = document.data()
                const message = new Message(document.id, data.sender, data.text, data.date, data.convertsation)
                messages.push(message)
            })
            
            if(callback != null){
                callback(messages)
            }
        })
        return unsub
    }

    // edit db
    async save(){
        const collectionRef = collection(db, collectionName)

        if(this.id == null){
            const response = await addDoc(collectionRef, {
                sender: this.sender,
                text: this.text,
                date: this.date,
                convertsation: this.convertsation
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                sender: this.sender,
                text: this.text,
                date: this.date,
                convertsation: this.convertsation
            })
        }
    }
    delete(){
        const documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Message