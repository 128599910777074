<template>
    <v-card-text>
        <v-list-item @click="leaveConv" class="mb-2">
            <v-list-item-content>
                <div class="d-flex align-center">
                    <v-icon large class="mr-3">mdi-chevron-left</v-icon>
                    <UserCard :profile="actualConv.users"></UserCard>
                </div>
            </v-list-item-content>
        </v-list-item>

        <v-card elevation="12">
            <v-card-text style="overflow:auto;height:58vh" ref="msgContainer" class="d-flex flex-column">
                <!-- start -->
                <v-spacer></v-spacer>
                <div class="d-flex flex-column align-center mt-5 mb-10">
                    <v-card-title v-text="'Ceci est le début de votre conversation avec ' + actualConv.users.firstname + ' ' + actualConv.users.lastname" style="text-align:center"></v-card-title>
                    <div class="d-flex align-center" style="width:100%">
                        <v-spacer></v-spacer>
                        <v-icon x-large style="transform:rotateY(180deg)">mdi-flag-checkered</v-icon>
                        <v-divider></v-divider>
                        <v-icon x-large>mdi-flag-checkered</v-icon>
                        <v-spacer></v-spacer>
                    </div>
                </div>
                <v-spacer></v-spacer>
                <div v-for="(message, i) in messages">
                    <!-- date -->
                    <div v-if="i == 0 || !sameDate(messages[i-1].date, message.date)" class="d-flex align-center mb-3">
                        <v-divider></v-divider>
                        <div v-text="getStrDate(message.date)" class="mx-3"></div>
                        <v-divider></v-divider>
                    </div>

                    <!-- premiers messages -->
                    <div v-if="i == 0 || messages[i-1].sender.id != message.sender.id || !sameDate(messages[i-1].date, message.date)" class="px-4 pt-2 pb-1 mt-3 d-flex msg">
                        <UserAvatar :profile="message.sender"></UserAvatar>
                        <div class="d-flex flex-column ml-2">
                            <b class="light--text" v-text="message.sender.firstname + ' ' + message.sender.lastname + ' :'"></b>
                            <div v-text="message.text"></div>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-text="getStrHour(message.date)" class="msgDate"></div>
                    </div>
                    
                    <!-- autres messages -->
                    <div v-else class="pl-16 pr-4 pb-1 d-flex msg">
                        <div v-text="message.text"></div>
                        <v-spacer></v-spacer>
                        <div v-text="getStrHour(message.date)" class="msgDate"></div>
                    </div>
                </div>
                
            </v-card-text>

            <!-- text field -->
            <v-card-text>
                <v-row class="d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-col cols="11">
                        <v-text-field autofocus v-model="configData.msg" @keydown="sendMessageWithEnter($event)" :placeholder="'Envoyer un message à ' + actualConv.users.firstname + ' ' + actualConv.users.lastname" clearable />
                    </v-col>
                    <v-btn color="primary" icon @click="newMessage">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card-text>
        </v-card>
    </v-card-text>
</template>

<style scoped>
.msg{
    margin-left: -20px;
    margin-right: -20px;
}
.msg:hover{
    background-color: var(--v-scrollBg-base);
}
.msg:hover > .msgDate{
    opacity: 0.7;
}
.msgDate{
    opacity: 0;
    transition: 250ms;
}
</style>

<script>
import Message from "@/classes/Communication/Message";
import notifier from "@/assets/functions/notifier";
import UserAvatar from "@/components/UserAvatar.vue"
import UserCard from "@/components/UserCard.vue"

export default {
    name: 'MessagesPage',
    props: ["profile", "actualConv"],
    components: { UserAvatar, UserCard },
    data: () => ({
        messages: [],
        configData: {
            msg: ''
        },

        unsub: []
    }),
    created(){
        this.unsub.push(Message.listenByConv(this.actualConv.id, (tmp_msgs) => {
            tmp_msgs.sort((a, b) => {
                return a.date - b.date
            })
            this.messages = []
            tmp_msgs.forEach(msg => {
                msg.sender = (msg.sender == this.profile.id) ? this.profile : this.actualConv.users
                this.messages.push(msg)
            })
        }))
    },
    updated(){
        this.$refs.msgContainer.scrollTop = this.$refs.msgContainer.scrollHeight
    },
    methods: {
        async newMessage(){
            if(this.configData.msg.trim() != ''){
                const new_msg = new Message(null, this.profile.id, this.configData.msg, new Date().getTime(), this.actualConv.id)
                await new_msg.save()
                this.configData.msg = ''
                this.$refs.msgContainer.scrollTop = this.$refs.msgContainer.scrollHeight

                this.actualConv.lastMsg = new_msg.date
                await this.actualConv.save()
                notifier.sendOne(this.actualConv.users.id, this.profile.currentRoom, "message", new_msg.date, this.profile.id, true)
            }
        },
        sendMessageWithEnter(event){
            if(event.key == 'Enter') this.newMessage()
        },
        sameDate(date1, date2){
            date1 = new Date(date1)
            date2 = new Date(date2)
            return date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getYear() == date2.getYear()
        },
        getStrDate(date){
            return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris', weekday: 'long', year: 'numeric', month: 'long', day: '2-digit'})
        },
        getStrHour(timestamp){
            const date = new Date(timestamp)
            return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        },
        leaveConv(){
            this.$emit("hideConv", this.actualConv)
        }
    },
    destroyed(){
        this.unsub.forEach((unsub) => unsub())
    }
}
</script>