import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

const db = getFirestore()
const collectionPrefix = envManager.getPrefix()
const collectionId = "conversation"
const collectionName = collectionPrefix + collectionId

class Conversation{
    constructor(id, users, lastMsg, room){
        this.id = id
        this.users = users
        this.lastMsg = lastMsg
        this.room = room
    }

    // get
    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        const conversations = []

        response.forEach(document => {
            const data = document.data()
            const tmp_conversation = new Conversation(document.id, data.users, data.lastMsg, data.room)
            conversations.push(tmp_conversation)
        })

        return conversations
    }
    static async getByUserAndByRoom(userId, roomId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('users', 'array-contains', userId), where('room', '==', roomId))
        const response = await getDocs(documentsQuery)

        const conversations = []

        response.forEach(document => {
            const data = document.data()
            const tmp_conversations = new Conversation(document.id, data.users, data.lastMsg, data.room)
            conversations.push(tmp_conversations)
        })
        return conversations
    }

    // listen
    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)
        const unsub = onSnapshot(collectionRef, snapshot => {
            const conversations = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_conversation = new Conversation(document.id, data.users, data.lastMsg, data.room)
                conversations.push(tmp_conversation)
            })

            if(callback != null){
                callback(conversations)
            }
        })
        return unsub
    }
    static listenByUserAndByRoom(userId, roomId, callback = null){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('users', 'array-contains', userId), where('room', '==', roomId))
    
        const unsub = onSnapshot(documentsQuery, snapshot => {
            const conversations = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_conversation = new Conversation(document.id, data.users, data.lastMsg, data.room)
                conversations.push(tmp_conversation)
            })
            
            if(callback != null){
                callback(conversations)
            }
        })
        return unsub
    }

    // edit db
    async save(){
        const collectionRef = collection(db, collectionName)

        if(this.id == null){
            const response = await addDoc(collectionRef, {
                users: this.users,
                lastMsg: this.lastMsg,
                room: this.room
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                lastMsg: this.lastMsg
            })
        }
    }
    delete(){
        const documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Conversation