<template>
    <v-card>
        <!-- CONTACTS -->
        <v-card-title v-if="convSelected == null">
            Conversations :
        </v-card-title>
        <v-card-text class="pt-0" v-if="convSelected == null">
            <v-list style="overflow:auto;max-height:73vh">
                <v-list-item v-for="conv in conversations" @click="selectAConv(conv)">
                    <v-list-item-content>
                        <UserCard :profile="conv.users" :notif="conv.nbNotif"></UserCard>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>

        <MessagesPage :profile="profile" :actualConv="convSelected" v-if="convSelected != null" @hideConv="selectAConv"></MessagesPage>

        <DialogNewConversation with="realPeople" :blacklist="conversations.map(conv => conv.users.id)" @convSelected="newConversation" ref="dialogNewConversation" :profile="profile"></DialogNewConversation>
        
        <v-row class="ma-0" v-if="convSelected === null">
            <v-col cols="12" class="d-flex justify-end">
                <v-btn color="primary" fab @click="$refs.dialogNewConversation.open()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import DialogNewConversation from "@/components/Communication/DialogNewConversation.vue"
import MessagesPage from "../components/Communication/MessagesPage.vue";
import Conversation from "@/classes/Communication/Conversation";
import Profile from "@/classes/Profile";
import UserCard from "@/components/UserCard.vue"

export default {
    name: "Message",
    props: ["profile", "notifications"],
    components: { DialogNewConversation, MessagesPage, UserCard },
    data: () => ({
        conversations: [],
        convSelected: null,

        unsub: []
    }),
    created(){
        this.unsub.push(Conversation.listenByUserAndByRoom(this.profile.id, this.profile.currentRoom, async (tmp_convs) => {
            const new_list = []
            tmp_convs.forEach(async conv => {
                const contactId = (this.profile.id == conv.users[0]) ? conv.users[1] : conv.users[0]
                conv.users = await Profile.getById(contactId)
                
                if(this.notifications && this.notifications.message){
                    this.countNotif(conv)
                }

                new_list.push(conv)
            })
            this.conversations = new_list
            this.conversations.sort((a, b) => {
                return b.lastMsg - a.lastMsg
            })
        }))
    },
    watch: {
        notifications: {
            handler(newVal, oldVal){
                if(this.notifications.message){
                    this.conversations.forEach(conv => {
                        this.countNotif(conv)
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async newConversation(user) {
            const tmp_Conv = new Conversation(null, [this.profile.id, user.id], null, this.profile.currentRoom)
            await tmp_Conv.save()
            const contactId = (this.profile.id == tmp_Conv.users[0]) ? tmp_Conv.users[1] : tmp_Conv.users[0]
            tmp_Conv.users = user
            this.selectAConv(tmp_Conv)
        },
        selectAConv(conv){
            if(this.convSelected == null){
                this.convSelected = conv
                
                if(this.notifications && this.notifications.message){
                    for(let notif of this.notifications.message){
                        if(notif.content == this.convSelected.users.id) notif.delete()
                        this.convSelected.nbNotif = 0
                    }
                }
            }else{
                this.convSelected = null
            }
        },
        countNotif(conv){
            conv.nbNotif = 0
            for(let notif of this.notifications.message){
                if(notif.content == conv.users.id) conv.nbNotif ++
                if(this.convSelected && notif.content == this.convSelected.users.id){
                    for(let notif of this.notifications.message){
                        notif.delete()
                        conv.nbNotif = 0
                    }
                }
            }
        }
    },
    destroyed(){
        this.unsub.forEach((unsub) => unsub())
    }
}
</script>